import AOS from "aos";
import lozad from "lozad";
import {
	setBackgroundDesktopElement,
	detectCloseElement,
	buttonToTop,
	clickScrollToDiv,
	appendCaptchaASP,
	clickScroll,
} from "./helper";
import { header } from "./header";
import swiperInit, { homeBanner } from "./swiper";
import { clickLoadMoreAjax } from "./utils";
import { CountUp } from "countup.js";
$(document).ready(function () {
	setBackgroundDesktopElement();
	header.init();
	swiperInit();
	appendCaptchaASP();
	appendBreadcrumb();
	SelectSortProvider();
	buttonToTop();
	clickLoadMoreAjax();
	handleReport();
	// clickScroll();

	// Custom
	appendFormProduct();
	ajaxProduct();
	ajaxNews();
	handleCountNumber();
	handleVideoBannerHomePage();
	// handleAdminNav();

	window.swiperInit = swiperInit;

	/*==================== Lazyload JS ====================*/
	var observer = lozad(); // lazy loads elements with default selector as '.lozad'
	observer.observe();
	window.observe = observer;

	/*==================== Aos Init ====================*/
	AOS.init({
		disable: "mobile",
		anchorPlacement: "top-bottom",
		once: true,
		delay: 100,
	});
});

function handleReport() {
	if (!$(".section-infomation-disclosure").length) return;
	console.log("Runn");
	$(".swiper-slide-active a").trigger("click");
}
/*==================== SelectSortProvider ====================*/
function SelectSortProvider() {
	function processAjax(url, nodeElement) {
		$.ajax({
			url: url,
			beforeSend: function (data) {
				$(".loading").addClass("active");
			},
			success: async function (data) {
				$(".ajaxResponse").html($(data).find(".ajaxResponse").html());
				if ($(".pages.newspager").length) {
					if ($(data).find(".pages").length) {
						$(".pages.newspager").html(
							$(data).find(".pages.newspager").html()
						);
					} else {
						$(".pages").html("");
					}
				} else {
					$(".ajaxResponse").append(
						$(data).find(".pages.newspager").html()
					);
				}
				$(".loading").removeClass("active");
				observer.observe();
			},
		});
	}
	$(".tab-select-provider select").on("change", function () {
		let value = $(this).val();
		console.log("🚀 ~ file: main.js:47 ~ value:", value);
		processAjax(value);
	});
	let ajaxTimeoutId;
	$(".ajaxLink").on("click", function (e) {
		e.preventDefault();
		let value = $(this).attr("href");
		debounceAjax(value);
		return false;
	});
	function debounceAjax(value) {
		if (ajaxTimeoutId) {
			clearTimeout(ajaxTimeoutId); // clear the previous timeout if it exists
		}
		ajaxTimeoutId = setTimeout(() => {
			processAjax(value); // call the processAjax() function after a debounce
		}, 500); // set a debounce timeout of 500ms (adjust the time as needed)
	}
}

/*==================== Append Breadcrumb ====================*/

function appendBreadcrumb() {
	if (!$(".page-banner-main").length) return;
	$(".global-breadcrumb").appendTo(
		".page-banner-main .wrap-global-breadcrumb"
	);
}

const observerPhase = new IntersectionObserver(
	(entries, observe) => {
		entries.forEach((entry) => {});
	},
	{
		threshold: [1],
		rootMargin: "0px",
	}
);
const nodeElementsTEst = document.querySelectorAll(
	".wrap-item-phase .text-phase-main"
);
nodeElementsTEst.forEach((ele) => {
	observerPhase.observe(ele);
});

const nodeElements = document.querySelectorAll(".text-phase-main");

window.addEventListener("scroll", function () {
	nodeElements.forEach((nodeElement) => {
		const rect = nodeElement.getBoundingClientRect();
		const position = rect.top + window.scrollY;
		const viewportTop = window.scrollY;
		const threshold = 200;
		const namePhase = $(nodeElement).data("phase");
		if (position <= viewportTop + threshold) {
			nodeElement.classList.add("active");
			$(`a[href="#${namePhase}"]`)
				.addClass("active-scroll")
				.parent()
				.siblings()
				.find("a")
				.removeClass("active-scroll");
			$(".wrap-phase-mobile .wrap-select select").val(namePhase).change();
		} else {
			$(`a[href="#${namePhase}"]`).removeClass("activ-scroll");
			nodeElement.classList.remove("active");
		}
	});
});
/*==================== Policy - Click Expand Content  ====================*/
function clickExpandContentPolicy() {
	if (!$(".section-page-policy").length) return;
	const $nodeBox = $(".wrap-content-main");
	const $button = $(".section-page-policy .btn-view-more");
	const paddingNodeBox = Number($nodeBox.css("padding-top").slice(0, 2));
	const totalHeightInit =
		$(".wrap-content-main .box-content-divide").eq(0).outerHeight() +
		$(".wrap-content-main .box-content-divide").eq(1).outerHeight() +
		paddingNodeBox * 2;
	const totalHeight =
		$nodeBox.find(".hidden-content").outerHeight() + paddingNodeBox * 2;
	$nodeBox.css("height", totalHeightInit);
	// $('.section-page-policy .btn-view-more').on('click', function () {
	// 	$nodeBox.css('height', totalHeight + 80)
	// })
	$button.on("click", function () {
		if ($(this).hasClass("expand")) {
			$nodeBox.css("height", totalHeightInit);
			$(this).find("span").text("Xem thêm");
		} else {
			$nodeBox.css("height", totalHeight);
			$(this).find("span").text("Rút gọn");
		}
		$(this).toggleClass("expand");
	});
}

function appendFormProduct() {
	if ($(".product-form").length != 0) {
		$(".product-form").insertBefore(".product-other");
	}
}

function handleAdminNav() {
	$("#pullmenucms").on("click", function () {
		$(".cmsadminpanel .listmenu").slideToggle();
	});
}

function ajaxProduct() {
	function ProcessAjax(pageurl) {
		//to get the ajax content and display in div with id 'content'
		$.ajax({
			url: pageurl,
			success: function (data) {
				console.log(data);
				$(".ajaxresponse").html($(data).find(".ajaxresponse").html());
				$(".tabs-product").tabslet();
				AOS.init({
					disable: "mobile",
					anchorPlacement: "top-bottom",
					once: true,
					delay: 100,
				});
			},
		});

		//to change the browser URL to 'pageurl'
		if (pageurl != window.location) {
			window.history.pushState(
				{
					path: pageurl,
				},
				"",
				pageurl
			);
		}
	}
	$(".productpage .item-product-cate a").on("click", function (e) {
		e.preventDefault();
		$(this).closest(".box-top").find(".item").removeClass("active");
		$(this).closest(".item").addClass("active");
		const hrefValue = $(this).attr("href");
		ProcessAjax(hrefValue);
		return false;
	});

	$("body").on("click", ".product-list .tabslet-tab a", function (e) {
		e.preventDefault();
		$(this).closest(".tabslet-tab").find("li").removeClass("active");
		$(this).closest("li").addClass("active");
		const hrefValue = $(this).attr("href");
		ProcessAjax(hrefValue);
		return false;
	});

	$("body").on("click", ".product-list .pagination a", function (e) {
		e.preventDefault();
		$(this).closest(".pagination").find("li").removeClass("active");
		$(this).closest("li").addClass("active");
		const hrefValue = $(this).attr("href");
		ProcessAjax(hrefValue);
		return false;
	});
}

function ajaxNews() {
	function ProcessAjax(pageurl) {
		//to get the ajax content and display in div with id 'content'
		$.ajax({
			url: pageurl,
			success: function (data) {
				$(".ajaxresponse").html($(data).find(".ajaxresponse").html());
				$(".ajaxresponse")
					.next()
					.find(".pagination")
					.html($(data).find(".pagination"));
				observe.observe();
			},
		});

		//to change the browser URL to 'pageurl'
		if (pageurl != window.location) {
			window.history.pushState(
				{
					path: pageurl,
				},
				"",
				pageurl
			);
		}
	}
	$(".news-2 .tabslet-tab a").on("click", function (e) {
		e.preventDefault();
		$(this).closest(".tabslet-tab").find("li").removeClass("active");
		$(this).closest("li").addClass("active");
		const hrefValue = $(this).attr("href");
		ProcessAjax(hrefValue);
		return false;
	});

	$("body").on("click", ".news-2 .pagination a", function (e) {
		e.preventDefault();
		$(this).closest(".pagination").find("li").removeClass("active");
		$(this).closest("li").addClass("active");
		const hrefValue = $(this).attr("href");
		ProcessAjax(hrefValue);
		return false;
	});
}

function handleCountNumber() {
	const couters = document.querySelectorAll(".counter");

	couters.forEach(function (item) {
		let demo = new CountUp(item, item.dataset.count, {
			separator: ".",
			enableScrollSpy: true,
		});
		if (!demo.error) {
			demo.start();
		} else {
			console.error(demo.error);
		}
	});
}

function handleVideoBannerHomePage() {
	const bannerItem = $(".home-banner-swiper .swiper-slide");

	bannerItem.each(function (index, item) {
		if ($(item).find(".video-wrapper").length > 0) {
			const videoEl = $(item).find("video");

			videoEl.on("play", function () {
				console.log("video play");
				homeBanner.autoplay.stop();
			});
			videoEl.on("ended", function () {
				console.log("video end");
				homeBanner.slideTo(index + 1);
				homeBanner.autoplay.start();
			});
		}
	});

	homeBanner.on("slideChange", function () {
		const hasVideo = bannerItem.eq(homeBanner.activeIndex).find("video");
		console.log(homeBanner.activeIndex);
		$("video")[0].pause();
		if (hasVideo.length !== 0) {
			bannerItem.eq(homeBanner.activeIndex).find("video")[0].play();
		} else {
			homeBanner.autoplay.start();
		}
	});
}
