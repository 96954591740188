import Swiper from "swiper";
import {
	Autoplay,
	EffectFade,
	Grid,
	Navigation,
	Pagination,
	Thumbs,
	Controller,
} from "swiper/modules";
/**
 * @param swiperInit
 */
export var homeBanner;
export default function swiperInit() {
	homeBanner = new Swiper(`.home-banner-swiper .swiper`, {
		modules: [Pagination, Autoplay],
		slidesPerView: 1,
		autoplay: {
			delay: 5000,
			stopOnLastSlide: true,
		},
		allowTouchMove: false,
		pagination: {
			el: `.home-banner-swiper .swiper-pagination`,
			type: "bullets",
			clickable: true,
		},
	});

	var home1 = new Swiper(`.home-1-swiper .swiper`, {
		modules: [Autoplay, Pagination, Navigation],
		slidesPerView: 2,
		spaceBetween: 12,
		// autoplay: {
		// 	delay: 5000,
		// },
		pagination: {
			el: `.home-1-swiper .swiper-pagination`,
			type: "bullets",
			clickable: true,
			dynamicBullets: true,
		},
		navigation: {
			prevEl: `.home-1-swiper .btn-prev`,
			nextEl: `.home-1-swiper .btn-next`,
		},
		breakpoints: {
			768: {
				slidesPerView: 4,
			},
			1024: {
				slidesPerView: 5,
				spaceBetween: 32,
			},
		},
	});

	var home3 = new Swiper(`.home-3-swiper .swiper`, {
		loop: true,
		modules: [Autoplay, Navigation],
		slidesPerView: 1,
		// autoplay: {
		// 	delay: 5000,
		// },
		navigation: {
			prevEl: `.home-3-swiper .btn-prev`,
			nextEl: `.home-3-swiper .btn-next`,
		},
	});

	if ($(`.home-3-swiper .swiper`).length != 0) {
		const totalItem = $(".home-3-swiper .swiper-slide").length;
		let currentItem = 1;

		$(".home-3-swiper .btn-prev").on("click", function () {
			if (currentItem <= 1) {
				currentItem = totalItem;
				$(".home-3-swiper .number-index").html(currentItem);
			} else {
				currentItem--;
				$(".home-3-swiper .number-index").html(currentItem);
			}
		});

		$(".home-3-swiper .btn-next").on("click", function () {
			if (currentItem >= totalItem) {
				currentItem = 1;
				$(".home-3-swiper .number-index").html(currentItem);
			} else {
				currentItem++;
				$(".home-3-swiper .number-index").html(currentItem);
			}
		});

		$(".home-3-swiper .number-index").html(currentItem);
		$(".home-3-swiper .total-index").html(totalItem);
	}

	var home4 = new Swiper(`.home-4-swiper .swiper`, {
		modules: [Autoplay, Pagination, Navigation],
		slidesPerView: 2,
		spaceBetween: 15,
		// autoplay: {
		// 	delay: 5000,
		// },
		pagination: {
			el: `.home-4-swiper .swiper-pagination`,
			type: "bullets",
			clickable: true,
			dynamicBullets: true,
		},
		navigation: {
			prevEl: `.home-4-swiper .btn-prev`,
			nextEl: `.home-4-swiper .btn-next`,
		},
		breakpoints: {
			576: {
				slidesPerView: 3,
			},
			1024: {
				slidesPerView: 5,
				spaceBetween: 120,
			},
		},
	});

	var home5 = new Swiper(`.home-5-swiper .swiper`, {
		modules: [Autoplay, Pagination, Navigation],
		slidesPerView: 2,
		spaceBetween: 15,
		// autoplay: {
		// 	delay: 5000,
		// },
		pagination: {
			el: `.home-5-swiper .swiper-pagination`,
			type: "bullets",
			clickable: true,
			dynamicBullets: true,
		},
		navigation: {
			prevEl: `.home-5-swiper .btn-prev`,
			nextEl: `.home-5-swiper .btn-next`,
		},
		breakpoints: {
			576: {
				slidesPerView: 2,
			},
			1024: {
				slidesPerView: 3,
				spaceBetween: 32,
			},
		},
	});

	// var about2Thumb = new Swiper(`.about-2 .swiper-thumb .swiper`, {
	// 	modules: [Autoplay, Pagination, Navigation, Thumbs, Controller],
	// 	slidesPerView: 3,
	// 	freeMode: true,
	// 	slideToClickedSlide: true,
	// 	watchSlidesProgress: true,
	// 	navigation: {
	// 		prevEl: `.about-2 .swiper-main .btn-prev`,
	// 		nextEl: `.about-2 .swiper-main .btn-next`,
	// 	},
	// 	pagination: {
	// 		el: `.about-2 .swiper-main .swiper-pagination`,
	// 		type: "bullets",
	// 		clickable: true,
	// 		dynamicBullets: true,
	// 	},
	// 	breakpoints: {
	// 		768: {
	// 			slidesPerView: 5,
	// 		},
	// 	},
	// });

	var about2Main = new Swiper(`.about-2 .swiper-main .swiper`, {
		modules: [Autoplay, Pagination, Navigation, Thumbs, Controller],
		slidesPerView: 2,
		spaceBetween: 30,
		// loop: true,
		// centeredSlides: true,
		// autoplay: {
		// 	delay: 5000,
		// },
		// thumbs: {
		// 	swiper: about2Thumb,
		// },
		// controller: {
		// 	control: about2Thumb,
		// },
		pagination: {
			el: `.about-2 .swiper-main .swiper-pagination`,
			type: "bullets",
			clickable: true,
			dynamicBullets: true,
		},
		navigation: {
			prevEl: `.about-2 .swiper-main .btn-prev`,
			nextEl: `.about-2 .swiper-main .btn-next`,
		},
		breakpoints: {
			768: {
				slidesPerView: 2,
				spaceBetween: 32,
			},
			1280: {
				slidesPerView: 3,
			},
		},
	});

	// about2Thumb.controller.control = about2Main;
	// about2Main.controller.control = about2Thumb;

	var about6 = new Swiper(`.about-6-swiper .swiper`, {
		modules: [Autoplay, Pagination, Navigation, Thumbs],
		slidesPerView: 2,
		spaceBetween: 15,
		// autoplay: {
		// 	delay: 5000,
		// },
		pagination: {
			el: `.about-6-swiper .swiper-pagination`,
			type: "bullets",
			clickable: true,
			dynamicBullets: true,
		},
		navigation: {
			prevEl: `.about-6-swiper .btn-prev`,
			nextEl: `.about-6-swiper .btn-next`,
		},
		breakpoints: {
			768: {
				slidesPerView: 3,
			},
			1024: {
				slidesPerView: 4,
				spaceBetween: 32,
			},
		},
	});

	var productList = new Swiper(`.box-top-swiper .swiper`, {
		modules: [Autoplay, Pagination, Navigation],
		slidesPerView: 2,
		spaceBetween: 15,
		// autoplay: {
		// 	delay: 5000,
		// },
		pagination: {
			el: `.box-top-swiper .swiper-pagination`,
			type: "bullets",
			clickable: true,
			dynamicBullets: true,
		},
		navigation: {
			prevEl: `.box-top-swiper .btn-prev`,
			nextEl: `.box-top-swiper .btn-next`,
		},
		breakpoints: {
			768: {
				slidesPerView: 4,
				spaceBetween: 32,
			},
			1024: {
				slidesPerView: 5,
			},
		},
	});

	var productThumb = new Swiper(`.product-detail .swiper-thumb .swiper`, {
		modules: [Autoplay, Navigation],

		slidesPerView: 3,
		spaceBetween: 12,
		// autoplay: {
		// 	delay: 5000,
		// },
		navigation: {
			prevEl: `.product-detail .swiper-thumb .btn-prev`,
			nextEl: `.product-detail .swiper-thumb .btn-next`,
		},
		breakpoints: {
			768: {
				slidesPerView: 5,
			},
			1024: {
				slidesPerView: 5,
				direction: "vertical",
			},
		},
	});

	var productMain = new Swiper(`.product-detail .swiper-main .swiper`, {
		modules: [Autoplay, Navigation, Thumbs],
		slidesPerView: 1,
		// autoplay: {
		// 	delay: 5000,
		// },
		thumbs: {
			swiper: productThumb,
		},
		navigation: {
			prevEl: `.product-detail .swiper-main  .btn-prev`,
			nextEl: `.product-detail .swiper-main  .btn-next`,
		},
	});

	var productOther = new Swiper(`.product-other-swiper .swiper`, {
		modules: [Autoplay, Pagination, Navigation],
		slidesPerView: 2,
		spaceBetween: 15,
		// autoplay: {
		// 	delay: 5000,
		// },
		pagination: {
			el: `.product-other-swiper .swiper-pagination`,
			type: "bullets",
			clickable: true,
			dynamicBullets: true,
		},
		navigation: {
			prevEl: `.product-other-swiper .btn-prev`,
			nextEl: `.product-other-swiper .btn-next`,
		},
		breakpoints: {
			1024: {
				slidesPerView: 3,
				spaceBetween: 32,
			},
		},
	});

	var news1 = new Swiper(` .news-1-swiper .swiper`, {
		modules: [Autoplay, Pagination, Navigation],
		slidesPerView: 2,
		spaceBetween: 15,
		// autoplay: {
		// 	delay: 5000,
		// },
		pagination: {
			el: ` .news-1-swiper .swiper-pagination`,
			type: "bullets",
			clickable: true,
			dynamicBullets: true,
		},
		navigation: {
			prevEl: ` .news-1-swiper .btn-prev`,
			nextEl: ` .news-1-swiper .btn-next`,
		},
		breakpoints: {
			768: {
				spaceBetween: 32,
			},
		},
	});

	var sustai2 = new Swiper(`.sustainability-2-swiper .swiper`, {
		modules: [Autoplay, Pagination, Navigation],
		slidesPerView: 2,
		spaceBetween: 15,
		// autoplay: {
		// 	delay: 5000,
		// },
		pagination: {
			el: `.sustainability-2-swiper .swiper-pagination`,
			type: "bullets",
			clickable: true,
			dynamicBullets: true,
		},
		navigation: {
			prevEl: `.sustainability-2-swiper .btn-prev`,
			nextEl: `.sustainability-2-swiper .btn-next`,
		},
		breakpoints: {
			1024: {
				slidesPerView: 3,
				spaceBetween: 32,
			},
		},
	});

	var sustai3 = new Swiper(`.sustainability-3 .swiper`, {
		modules: [Autoplay, Pagination, Navigation],
		slidesPerView: 2,
		spaceBetween: 15,
		// autoplay: {
		// 	delay: 5000,
		// },
		pagination: {
			el: `.sustainability-3 .swiper-pagination`,
			type: "bullets",
			clickable: true,
			dynamicBullets: true,
		},
		navigation: {
			prevEl: `.sustainability-3 .btn-prev`,
			nextEl: `.sustainability-3 .btn-next`,
		},
		breakpoints: {
			576: {
				slidesPerView: 3,
			},
			1024: {
				slidesPerView: 5,
				spaceBetween: 120,
			},
		},
	});

	$(".sustainability-5 .sustainability-5-swiper").each(function (index) {
		let nameClass = `sustainability-5-swiper-${index}`;
		$(this).addClass(nameClass);

		new Swiper(`.${nameClass} .swiper`, {
			modules: [Autoplay, Pagination, Navigation],
			slidesPerView: 1,
			spaceBetween: 15,
			observeParents: true,
			observer: true,
			// autoplay: {
			// 	delay: 5000,
			// },
			pagination: {
				el: `.${nameClass} .swiper-pagination`,
				type: "bullets",
				clickable: true,
				dynamicBullets: true,
			},
			navigation: {
				prevEl: `.${nameClass} .btn-prev`,
				nextEl: `.${nameClass} .btn-next`,
			},
			breakpoints: {
				576: {
					slidesPerView: 2,
				},
				1024: {
					slidesPerView: 3,
					spaceBetween: 32,
				},
			},
		});
	});

	$.each($(".wwd-detail .info .item"), function (index) {
		// console.log($(`.wwd-detail .info .item-${index+1}`));

		new Swiper(`.wwd-detail .info .item-${index + 1} .swiper`, {
			modules: [Navigation],
			navigation: {
				prevEl: `.wwd-detail .info .item-${index + 1} .btn-prev`,
				nextEl: `.wwd-detail .info .item-${index + 1} .btn-next`,
			},
		});
	});
}
